import React from 'react'
import { CompanyMedalCardsDashboardMainDiv } from '../styles'
import PropTypes from 'prop-types';
import { ImageUrl } from '../../../utils/constants';
import { RewardsHorizontalLine } from '../../../utils/icons';

function CompanyMedalCardsDashboard({companyRewardDetails}) {
  
  window.console.log("medal loaded");
  return (
    <React.Fragment>

      {companyRewardDetails?.map((tier, index)=>(
        <CompanyMedalCardsDashboardMainDiv key={index}>
          <div className="cardDiv">
            <div className='cardFirstRow'>
              <div className="cardImage" style={{backgroundColor: tier?.bg_color}}>
                <img src={ImageUrl + tier?.icon} />
              </div>
              <div className="cardDetails">
                <div className="cardTitle">
                  {tier?.display_name} Badge
                </div>
              </div>
            </div>
            <div className='cardSecondRow'>
              <div className="cardPoints">
                <span className='coloredPoints' style={{color: tier?.point_color}}>{tier?.tier_point} </span>
                <span>Points Goal</span>
              </div>
            </div>
            <div className="cardThirdRow">
              {RewardsHorizontalLine()}
              {/* <hr /> */}
            </div>
            <div className="cardFourthRow">
              <span className='coloredText' style={{color: tier?.point_color}}>{tier?.user_count} People</span>
              <span className='normalText'> Achieved</span>
            </div>  
          </div>
        </CompanyMedalCardsDashboardMainDiv>
      ))}
    </React.Fragment>
    
  )
}

CompanyMedalCardsDashboard.propTypes = {
  companyRewardDetails: PropTypes.object,
};

export default CompanyMedalCardsDashboard
