import React from 'react'
import { DeleteDocumentModal } from '../../styles'
import PropTypes from 'prop-types'
import { ImageUrl } from '../../../../utils/constants';

function DeletePopupModal({showModal, setShowDeleteModal, deleteRewardsDocument, doc_id}) {
  return (
    <DeleteDocumentModal show={showModal}>
      <div className="modalHeader">
        Delete File
      </div>
      <div className="modalContent">
        <div className="image">
          <img src={ImageUrl+""+"/images/rewards-management/trash_image.png"} />
        </div>
        <div className="messageSection">
          <div className="mainMessage">
            Are you sure you want to Delete <span>This Document</span>
          </div>
          <div className="subMessage">
            Once it is removed it can’t be recovered
          </div>
        </div>
      </div>
      <div className="modalFooter">
        <div className="yesButton" onClick={() => deleteRewardsDocument(doc_id)}>
          Yes, Delete It!
        </div>
        <div className="cancelButton" onClick={()=>{setShowDeleteModal(false)}}>
          Cancel
        </div>
      </div>
    </DeleteDocumentModal>
  )
}

DeletePopupModal.propTypes = {
  showModal: PropTypes.bool,
  setShowDeleteModal: PropTypes.func,
  deleteRewardsDocument: PropTypes.func,
  doc_id: PropTypes.number
};

export default DeletePopupModal
