import React from 'react'
import { CustomPopupModalDiv } from '../styles'
import { RewardsPointsManagementWarningIcon } from '../../../utils/icons'

function CustomPopupNewUI() {
  return (
    <>
      <CustomPopupModalDiv show={true} width={"600px"} height="auto">
        <div className="titleDiv">
          <div className="image">
            {RewardsPointsManagementWarningIcon()}
          </div>
          <div className="titleText">
            Warning
          </div>
        </div>

        <div className="messageDiv">
          Would you like to change the rewards<br/> bucket cap?<br/>
          Please note that once changed, it cannot <br/> be altered for one year.
        </div>

        <div className="buttonDiv">
          <input type="button" value="Modify Changes" className='submitButton' />
          <input type="button" value="Cancel" className='cancelButton' />
        </div>
      </CustomPopupModalDiv>
    </>
  )
}

export default CustomPopupNewUI